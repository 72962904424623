import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"
import { updateUser, logout, loadUser } from "../store/actions/authActions"
import { navigate } from "gatsby"
import { BASE_URL } from "../utils/urls"

const Profile = ({
  user,
  errors,
  updateUser,
  logout,
  loadUser,
  isAuthenticated,
}) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [profilePicture, setProfilePicture] = useState("")

  useEffect(() => {
    if (isAuthenticated !== true) {
      loadUser()
        .then(() => {
          if (user) {
            setName(user.name)
            setEmail(user.email)
            setPhoneNumber(user.phone_numbers)
          }
        })
        .catch(() => {
          navigate("/login")
        })
    } else {
      if (user) {
        setName(user.name)
        setEmail(user.email)
        setPhoneNumber(user.phone_numbers)
      }
    }
  }, [user])

  const handleUpdateUserInfo = e => {
    e.preventDefault()
    updateUser({
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      profilePicture: profilePicture,
    })
      .then(() => {
        console.log("success")
      })
      .catch(() => {
        console.log("Noooooope")
      })
  }

  const handleLogout = () => {
    logout()
      .then(() => {
        console.log("Logout success")
        navigate("/")
      })
      .catch(() => {
        console.log("Logout error")
      })
  }

  const pageName = "MON COMPTE"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="container mx-auto flex flex-wrap justify-center py-6">
        <div className="w-1/4 px-4 pt-4 rounded shadow mx-4">
          {user && user.profile_picture ? (
            <img
              src={BASE_URL + "/storage/" + user.profile_picture}
              alt=""
              className="w-autp h-32 mx-auto rounded-full"
            />
          ) : (
            <img
              src="https://ui-avatars.com/api/?size=512&rounded=true&name=Mory+DOSSO&color=fff&background=0056B2"
              alt=""
              className="w-32 h-32 mx-auto"
            />
          )}
          <div className="text-xs font-semibold text-gray-700 pt-0 text-center">
            {user ? user.name : ""}
          </div>
          <div className="text-xs text-gray-700 pt-0 text-center">
            {user ? user.phone_numbers : ""}
          </div>
          <div className="text-xs font-semibold text-blue-600 pt-0 italic text-center pb-2">
            {user ? user.email : ""}
          </div>
          <div className="border-t py-2">
            <div className="text-xs font-semibold">Nom complet</div>
            <div className="text-xs">{user ? user.name : ""}</div>
          </div>
          <div className="border-t py-2">
            <div className="text-xs font-semibold">N° de téléphone</div>
            <div className="text-xs">{user ? user.phone_numbers : ""}</div>
          </div>
          <div className="border-t py-2">
            <div className="text-xs font-semibold">Adresse email</div>
            <div className="text-xs">{user ? user.email : ""}</div>
          </div>
          <div className="border-t py-2">
            <div className="text-xs font-semibold">
              Compte existant depuis le
            </div>
            <div className="text-xs">
              {user ? moment(user.created_at).format("DD/MM/YYYY à HH:mm") : ""}
            </div>
          </div>
          <div className="border-t py-2">
            <button
              onClick={handleLogout}
              className="rounded-full w-full bg-green-500 hover:bg-green-700 text-white py-2 text-xs"
            >
              Déconnexion
            </button>
          </div>
        </div>
        <form
          onSubmit={handleUpdateUserInfo}
          className="w-2/3 px-4 py-4 rounded shadow mx-4"
        >
          {errors ? errors : ""}
          <div className="border-b pt-2">
            <span className="text-base font-semibold text-gray-700">
              Modifier les infos de mon compte
            </span>
          </div>
          <div className="w-full pt-5 pb-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              Nom <span className="text-green-600">*</span>
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              id="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </div>
          <div className="w-full py-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="phone-number"
            >
              N° de téléphone <span className="text-green-600">*</span>
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              id="phone-number"
              type="text"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="w-full py-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email-address"
            >
              Adresse email <span className="text-green-600">*</span>
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              id="email-address"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="w-full py-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="password"
            >
              Photo de profil
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-xs"
              id="profilePicture"
              type="file"
              onChange={e => setProfilePicture(e.target.files[0])}
            />
          </div>
          <div className="w-full py-3">
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Modifier mes informations
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
  }
}
const mapDispatchToProps = {
  updateUser,
  logout,
  loadUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
