import React from "react"
import { connect } from "react-redux"
import bgImage from "../images/slide-page.jpg"

const Slider2 = ({ title }) => {
  return (
    <div className="flex justify-center">
      <div
        className="flex flex-col text-center pt-48 pb-40 w-full leading-none"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="text-4xl font-bold text-white">{title}</div>
      </div>
    </div>
  )
}

export default connect(null, null)(Slider2)
